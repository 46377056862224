<template>
  <b-overlay :show="loading">
    <div class="blog-wrapper">

      <!-- blogs -->
      <div>
        <div class="mb-3">
          <b-row class="mt-2">
            <b-col cols="12" md="10">
              <b-input-group class="input-group-merge">
                <b-form-input v-model="searchFilter" placeholder="Search" size="lg" class="search-product" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col cols="12" md="2">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" size="lg" :to="{ name: 'admin-new-blog' }">
                  <span class="text-nowrap">Add New Post</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <list-blogs
          v-if="filteredBlogPosts.length > 0"
          :items="filteredBlogPosts"
          :per-page="perPage"
          :total-records="totalRecords"
          :current-page.sync="currentPage"
          :detail-page="'admin-single-blog'"
          :delete-title="'Delete Blog?'"
          :delete-message="'You are about to delete a blog post. Continue?'"
          @delete-item="onDelete"
        />

        <div v-else-if="!loading">
          <h3>No content available.</h3>
        </div>
      </div>

    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, 
  BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, 
  BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
  BButton
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter';
import { get } from "lodash";

import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListBlogs from "@/@core/components/shared/Miscellaneous/ListBlogs.vue"

const watchHandler = {
  handler(){
    this.fetchAllData()
  }, 
  immediate: false
}

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
    BButton,
    ListBlogs
  },
  data() {
    return {
      searchFilter: '',
      blogPosts: [],
      totalRecords: 0,
      currentPage: 1,
      perPage: 50,
      rows: 140,
      loading: false,
      recent_loading: false
    }
  },
  computed: {
    dataMeta(){
      const localItemsCount = this.loans.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    },
    filteredBlogPosts() {
      return this.blogPosts.filter(this.canView)
    }
  },
  watch: {
    perPage: watchHandler,
    searchFilter: watchHandler,
    currentPage: watchHandler,
    statusFilter: watchHandler,
    dateStart: watchHandler,
    dateEnd: watchHandler,
  },
  created() {
    this.fetchAllData();
  },
  methods: {
     async onDelete(blog_id){
      try {
        this.loading = true;
        await this.useJwt().adminService.deleteBlogPost(blog_id);

        this.blogPosts = this.blogPosts.filter(blog => blog._id !== blog_id);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Blog Post deleted successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter,
          status: this.statusFilter,
          from: this.dateStart,
          to: this.dateEnd
        }

        const request = await this.useJwt().sharedService.fetchBlogPosts(query);
        const { data, pagination } = request.data;
        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.blogPosts = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
